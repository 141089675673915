module.exports=[
  {
    "title": "gremlins-jquery",
    "description": "gremlin.js jQuery mixin supporting element and event maps",
    "author": "grmlin",
    "repository": "https://github.com/grmlin/gremlins-jquery",
    "npm": "https://www.npmjs.com/package/gremlins-jquery"
  },
  {
    "title": "gremlins-dispatcher",
    "description": "gremlin.js dispatcher mixin. Easy component interoperability",
    "author": "grmlin",
    "repository": "https://github.com/grmlin/gremlins-dispatcher",
    "npm": "https://www.npmjs.com/package/gremlins-dispatcher"
  },
  {
    "title": "gremlins-data",
    "description": "gremlin.js element data mixin for html5 data-attributes handling",
    "author": "grmlin",
    "repository": "https://github.com/grmlin/gremlins-data",
    "npm": "https://www.npmjs.com/package/gremlins-data"
  }
]